import {
  InferenceParameters,
  InferenceQuality,
  ModelProviderType,
} from "../../../../types";
import { DEFAULT_FACE_LIMIT, ReferenceImageType } from "../../constants";
import { formatReferenceImageForAPI, getImageObject } from "../../utils/utils";
import { FormOption3D } from "./components/Options3D/Options3D";

export const MODEL_PROVIDER_OPTIONS: ModelProviderType[] = [
  "TRIPO",
  "RODIN",
] as const;

export const ModelProviderToNameMap = new Map<ModelProviderType, string>([
  ["TRIPO", "TripoAI"],
  ["RODIN", "Rodin"],
]);

export const FORM_FIELDS_3D = {
  PROVIDER: "provider",
  TARGET_FILE: "targetFile",
  PROMPT_TEXT: "promptText",
  NEGATIVE_PROMPT_TEXT: "negativePromptText",
  PROMPT_LANGUAGE: "promptLanguage",
  TEXTURE: "texture",
  PERFORMANCE: "performance",
  FACE_LIMIT: "faceLimit",
  A_POSE: "a-pose",
  T_POSE: "t-pose",
  OPTION: "option",
} as const;

export interface FormType3D {
  [FORM_FIELDS_3D.PROVIDER]: ModelProviderType;
  [FORM_FIELDS_3D.TARGET_FILE]: ReferenceImageType;
  [FORM_FIELDS_3D.PROMPT_TEXT]?: string;
  [FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT]?: string;
  [FORM_FIELDS_3D.PROMPT_LANGUAGE]: string;
  [FORM_FIELDS_3D.TEXTURE]: boolean;
  [FORM_FIELDS_3D.FACE_LIMIT]: number;
  [FORM_FIELDS_3D.PERFORMANCE]: InferenceQuality;
  [FORM_FIELDS_3D.A_POSE]: number[];
  [FORM_FIELDS_3D.T_POSE]: number[];
  [FORM_FIELDS_3D.OPTION]: FormOption3D;
}

export const DEFAULT_3D_FORM_VALUES: FormType3D = {
  [FORM_FIELDS_3D.PROVIDER]: "TRIPO",
  [FORM_FIELDS_3D.TARGET_FILE]: null,
  [FORM_FIELDS_3D.PROMPT_TEXT]: "",
  [FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT]: "",
  [FORM_FIELDS_3D.PROMPT_LANGUAGE]: null,
  [FORM_FIELDS_3D.TEXTURE]: true,
  [FORM_FIELDS_3D.FACE_LIMIT]: DEFAULT_FACE_LIMIT,
  [FORM_FIELDS_3D.PERFORMANCE]: "MEDIUM",
  [FORM_FIELDS_3D.A_POSE]: [1, 1, 1, 1, 9],
  [FORM_FIELDS_3D.T_POSE]: [1, 1, 1, 1, 9],
  [FORM_FIELDS_3D.OPTION]: "IMAGE",
};

export const getDefaultForm3DValues = () =>
  JSON.parse(JSON.stringify(DEFAULT_3D_FORM_VALUES));

export const format3DFormValuesForAPI = (
  inputForm: FormType3D,
  defaultParameters: InferenceParameters,
): InferenceParameters => {
  const generationType =
    inputForm[FORM_FIELDS_3D.OPTION] === "IMAGE" ? "IMAGE_TO_3D" : "TEXT_TO_3D";

  const parameters: InferenceParameters = {
    ...defaultParameters,
    generationType: generationType,
    batchSize: 1,
    styles: [],
    modelProvider: inputForm[FORM_FIELDS_3D.PROVIDER],
    includeTextures: inputForm[FORM_FIELDS_3D.TEXTURE],
    faceLimit: inputForm[FORM_FIELDS_3D.FACE_LIMIT],
    // Text2Model
    prompt: inputForm[FORM_FIELDS_3D.PROMPT_TEXT],
    negativePrompt: inputForm[FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT],
    promptLanguage: inputForm[FORM_FIELDS_3D.PROMPT_LANGUAGE],
  };

  // Img2Model
  if (inputForm[FORM_FIELDS_3D.TARGET_FILE]) {
    parameters.files = [
      formatReferenceImageForAPI(inputForm[FORM_FIELDS_3D.TARGET_FILE]),
    ];
  }

  return parameters;
};

export const formatInferenceParamsForFormType3D = async (
  params: InferenceParameters,
): Promise<Partial<FormType3D>> => {
  const outputForm: Partial<FormType3D> = {};

  if (params) {
    if (params.files?.length) {
      const file = params.files[0];
      const imageObject = await getImageObject(file.url);
      outputForm[FORM_FIELDS_3D.TARGET_FILE] = {
        src: file.url,
        width: imageObject.width,
        height: imageObject.height,
      };
    }
    if (typeof params.prompt !== "undefined") {
      outputForm[FORM_FIELDS_3D.PROMPT_TEXT] = params.prompt;
    }
    if (typeof params.negativePrompt !== "undefined") {
      outputForm[FORM_FIELDS_3D.NEGATIVE_PROMPT_TEXT] = params.negativePrompt;
    }
    if (typeof params.promptLanguage !== "undefined") {
      outputForm[FORM_FIELDS_3D.PROMPT_LANGUAGE] = params.promptLanguage;
    }
    if (typeof params.includeTextures !== "undefined") {
      outputForm[FORM_FIELDS_3D.TEXTURE] = params.includeTextures;
    }
    if (typeof params.faceLimit !== "undefined") {
      outputForm[FORM_FIELDS_3D.FACE_LIMIT] = params.faceLimit;
    }
    if (typeof params.generationType !== "undefined") {
      const modelMode =
        params.generationType === "TEXT_TO_3D" ? "TEXT" : "IMAGE";
      outputForm[FORM_FIELDS_3D.OPTION] = modelMode;
    }
  }
  return outputForm;
};
